export const StoreType = [
  {
    name: 'animate',
    label: '动漫模型周边',
  },
  {
    name: 'cloth',
    label: '时尚服饰',
  },
  {
    name: 'bag',
    label: '精品包',
  },
  {
    name: 'watch',
    label: '手表',
  },
  {
    name: 'cook',
    label: '家电厨具',
  },
  {
    name: 'jewelry',
    label: '珠宝饰品',
  },
  {
    name: '3C',
    label: '3C电子',
  },
  {
    name: 'cosmetic',
    label: '化妆品香水',
  },
  {
    name: 'sport',
    label: '运动用品',
  },
  {
    name: 'music',
    label: '乐器',
  },
  {
    name: 'game',
    label: '电玩游戏',
  },
  {
    name: 'health',
    label: '健康保健',
  },
];
