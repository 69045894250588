const myMixin = {
  components: {},
  props: {},
  data() {
    return {
      isBannerLoadDone: false,
    };
  },
  mounted() {
    this.$store.commit('setLoading', true);
    setTimeout(() => {
      this.$store.commit('setLoading', false);
    }, 300);
  },
  methods: {
    bannerLoadDone() {
      this.isBannerLoadDone = true;
    },
  },
};

export default myMixin;
