<template>
  <div id="ChooseType">
    <div class="TypeItem" v-for="(item, index) in types" :key="index">
      <div class="topTitle"> {{ item.label }}</div>
      <div class="itemImg">
        <img :src="require(`@/assets/store/${item.name}.png`)" alt="" />
      </div>
      <div class="bottomBlock">
        <div class="seeMoreButton" @click="btnClickHandler(item)">
          查看更多
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import { StoreType } from '@/Config';
  export default {
    name: 'ChooseType',
    data() {
      return {};
    },
    computed: {
      types() {
        return StoreType;
      },
    },
    mounted() {},
    methods: {
      btnClickHandler(item) {
        this.$emit('click', item);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #ChooseType {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 28px;
    margin-bottom: 100px;
    .TypeItem {
      width: 25%;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        width: 50%;
      }
      .topTitle {
        font-size: 1.25rem;
        font-weight: bold;
        color: #000;
        text-align: center;
        margin: 15px 0;
      }
      .itemImg {
        display: flex;
        justify-content: center;
        img {
          display: block;
          width: 60%;
          max-width: 160px;
          height: auto;
        }
      }
      .bottomBlock {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        .seeMoreButton {
          display: flex;
          align-items: center;
          color: #707070;
          padding: 5px 10px;
          border: 1px solid #707070;
          cursor: pointer;
          &:hover {
            color: white;
            background-color: #707070;
          }
        }
      }
    }
  }
</style>
